.action-button {
    width: 100%;
    border-radius: 4px;
    background: linear-gradient(276deg, #211951 2.04%, #836FFF 97.47%);
    border: 0;
    cursor: pointer;
}

.action-button:disabled {
    background: #cccccc;
    cursor: not-allowed;
}

.action-button-text {
    color: #FFF;
    font-family: "Exo 2";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
}

.action-button-white-border {
    border: 1px solid #FFF;
}

.action-button-black-border {
    border: 1px solid #000;
}

.action-button-transparent {
    width: 100%;
    border-radius: 4px;
    background: transparent;
    border: 1px solid #000;
    cursor: pointer;
}

.action-button-text-transparent {
    color: #000;
    font-family: "Exo 2";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
}