.menu-input-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.menu-input {
    width: 100%;
    height: 30px;
    flex-shrink: 0;
    border-radius: 4px;
    border: 1px solid #CECECE;
    background: #FFF;

    color: #7B7B7B;
    font-family: "Exo 2";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
}