.login-bar-image {
    width: 85%;
    height: auto;
    border-radius: 24px;
    transform: translateX(12.5%);
}

.login-logo-image {
    position: absolute;
    top: 40px;
    left: 160px;
}

.login-error-message {
    color: red;
}