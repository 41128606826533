.upload-logo::-webkit-file-upload-button {
    visibility: hidden;
}
.upload-logo::before {
    content: 'Prześlij';
    display: inline-block;
    background: linear-gradient(276deg, #211951 2.04%, #836FFF 97.47%);
    height: 36px;
    width: 100%;
    border: 0;
    border-radius: 4px;
    cursor: pointer;
    outline: none;
    white-space: nowrap;
    -webkit-user-select: none;
    color: #FFF;
    font-family: "Exo 2";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    text-align: center;
    line-height: 36px;
}

.upload-logo-disabled::-webkit-file-upload-button {
    visibility: hidden;
}
.upload-logo-disabled {
    content: 'Prześlij logo';
    display: inline-block;
    background: #cccccc;
    height: 36px;
    width: 100%;
    border: 0;
    border-radius: 4px;
    cursor: not-allowed;
    outline: none;
    white-space: nowrap;
    -webkit-user-select: none;
    color: #FFF;
    font-family: "Exo 2";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    text-align: center;
    line-height: 36px;
}