.login-input {
    width: 100%;
    padding-bottom: 16px;
    border: 0;
    border-bottom: 2px solid #211951;

    color: #7B7B7B;
    font-family: "Exo 2";
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.login-input:focus {
    outline: none;
}