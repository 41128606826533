.markdown-container {
    position: relative;
    max-width: 100%;
    left: 50%;
    transform: translateX(-50%);
}

.markdown-container > p {
    font-size: 16px;
    line-height: 19px;
    font-weight: normal;
}

.markdown-container > table {
    width: 100%;
}

.markdown-container > table > thead > tr {
    font-family: inherit;
    color: inherit;
    text-align: left;
    font-size: 18px;
}

.markdown-container > table > thead > tr > th {
    font-family: inherit;
    color: inherit;
    font-size: 16px;
    line-height: 19px;
}

.markdown-container > table > tbody > tr {
    font-family: inherit;
    color: inherit;
    font-size: 16px;
    line-height: 19px;
}

.markdown-container > table > tbody > tr > td {
    font-family: inherit;
    color: inherit;
    font-size: 16px;
    line-height: 19px;
}

.markdown-container > ul {
    list-style-type: none;
    padding: 0;
}

.markdown-container > img {
    width: 100%;
    height: auto;
}

.markdown-container > p > img {
    width: 100%;
    height: auto;
}

.markdown-container > ul > img {
    width: 100%;
    height: auto;
}

.markdown-container > ul > li > img {
    width: 100%;
    height: auto;
}

.menu-not-found {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.menu-content {
    width: calc(100vw - 30px);
    height: 100%;
    padding: 15px;
}

.menu-preview {
    display: flex;
    justify-content: center;
    overflow: hidden;
    width: 350px;
}

.menu-container {
    display: flex;
    justify-content: center;
}

.menu-logo {
    position: relative;
    width: 50%;
    left: 50%;
    transform: translateX(-50%);
    height: auto;
}

.menu-darboven-logo-container {
    gap: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    border-top: 1px solid gray;
    padding-top: 16px;
    padding-bottom: 8px;
    margin-top: 32px;
    font-size: 14px;
    color: gray;
}

.menu-darboven-logo {
    width: 50%;
    height: auto;
    cursor: pointer;
}