@import url(https://fonts.googleapis.com/css2?family=Exo+2:ital,wght@0,100..900;1,100..900&display=swap);
@import url(https://fonts.googleapis.com/css?family=Lato:400,300,300italic,400italic,700,700italic&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Nunito:ital,wght@0,200..1000;1,200..1000&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);

html,
body {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    left: 0;
    top: 0;
    font-size: 100%;
}

main {
    min-width: 1100px;
}

* {
    font-family: "Exo 2";
    color: #000;
    line-height: normal;
    margin: 0;
}

h1 {
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
}

h2 {
    font-size: 2rem;
}

h3 {
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
}

h4 {
    font-size: 1.125rem;
}

h5 {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
}

h6 {
    font-size: 0.875rem;
}

p {
    font-size: 1.125rem;
    font-weight: 200;
    line-height: 1.8;
}

span {
    color: #7B7B7B;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
}

.font-light {
    font-weight: 300;
}

.font-regular {
    font-weight: 400;
}

.font-heavy {
    font-weight: 700;
}

.col-1 {
    width: 8.33%;
}

.col-2 {
    width: 16.66%;
}

.col-3 {
    width: 25%;
}

.col-4 {
    width: 33.33%;
}

.col-5 {
    width: 41.66%;
}

.col-6 {
    width: 50%;
}

.col-7 {
    width: 58.33%;
}

.col-8 {
    width: 66.66%;
}

.col-9 {
    width: 75%;
}

.col-10 {
    width: 83.33%;
}

.col-11 {
    width: 91.66%;
}

.col-12 {
    width: 100%;
}

.desktop-container {
    display: flex;
    padding-left: 160px;
    padding-right: 160px;
    min-height: 100vh;
    min-width: 1136px;
}
@media only screen and (max-width: 1500px) {
    .desktop-container {
        padding-left: 32px;
        padding-right: 32px;
    }
}

.flex {
    display: flex;
}

.flex-column {
    display: flex;
    flex-direction: column;
}

.align-center {
    align-items: center;
}

.justify-center {
    justify-content: center;
}

.space-between {
    justify-content: space-between;
}

.m-0 {
    margin: 0;
}

.ml20 {
    margin-left: 20px;
}

.mr20 {
    margin-right: 20px;
}

.mr32 {
    margin-right: 32px;
}

.mb4 {
    margin-bottom: 4px;
}

.mb8 {
    margin-bottom: 8px;
}

.mb21 {
    margin-bottom: 21px;
}

.gap-6 {
    gap: 6px;
}

.gap-11 {
    gap: 11px;
}

.gap-16 {
    gap: 16px;
}

.gap-24 {
    gap: 24px;
}

.gap-32 {
    gap: 32px;
}

.gap-40 {
    gap: 40px;
}

.gap-56 {
    gap: 56px;
}

.gap-74 {
    gap: 74px;
}

.text-center {
    text-align: center;
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

.flex-wrap {
    flex-wrap: wrap;
}

.white-text {
    color: #FFF;
}

.black-text {
    color: #000;
}

.none {
    display: none;
}

.dark-mode {
    background-color: #161616 !important;
}