.button {
    width: 100%;
    height: 64px;
    border-radius: 4px;
    background: linear-gradient(276deg, #211951 2.04%, #836FFF 97.47%);
    border: 0;
    cursor: pointer;
}

.button:disabled {
    background: #cccccc;
    cursor: not-allowed;
}

.button-text {
    color: #FFF;
    font-family: "Exo 2";
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
}