.dashboard-border {
    border-radius: 24px;
    border: 1px solid #D8D1FF;
    background: #FFF;
    box-shadow: 10px 10px 24px 0 rgba(131, 111, 255, 0.15);
    height: fit-content;
}

.dashboard-tile {
    display: flex;
    flex-direction: column;
    padding: 24px 24px 32px 24px;
    gap: 24px;
}

.dashboard-quick-help-tile {
    display: flex;
    flex-direction: column;
    padding: 24px 24px 0 24px;
}

.dashboard-tile-purple {
    border-radius: 24px;
    border: 1px solid #D8D1FF;
    background: linear-gradient(276deg, #211951 2.04%, #836FFF 97.47%);
    box-shadow: 10px 10px 24px 0 rgba(131, 111, 255, 0.15);
}

.dashboard-logo-container {
    width: 100%;
    height: 103px;
    flex-shrink: 0;
    border-radius: 4px;
    border: 1px solid #CECECE;
    background: #FFF;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dashboard-preview-text {
    display: block;
    text-align: center;
    color: #7B7B7B;
    font-family: "Exo 2";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
}

.dashboard-quick-help-text {
    color: #000;
    font-family: "Exo 2";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration: none;
}

.cursor-pointer {
    cursor: pointer;
}

.cursor-not-allowed {
    cursor: not-allowed;
}

.dashboard-quick-help-container {
    padding: 25px 0 25px 0;
}

.dashboard-quick-help-border {
    border-bottom: 1px solid #CECECE;
}

.dashboard-preview-container {
    overflow: hidden;
}

.dashboard-margin {
    margin-top: 120px;
    padding-bottom: 60px;
}

.dashboard-phone-preview-container {
    display: flex;
    justify-content: center;
}

.dashboard-phone-preview {
    border: 4px solid black;
    border-radius: 25px;
    display: flex;
    justify-content: center;
    overflow: hidden;
    width: 300px;
    height: 500px;
}

.dashboard-phone-preview-content {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    box-sizing: content-box;
    padding: 0 15px;
}

.dashboard-phone-preview-content::-webkit-scrollbar {
    width: 0;
}

.dashboard-logo {
    display: block;
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
}

.CodeMirror-scroll {
    min-height: 405px;
    max-height: 405px;
}