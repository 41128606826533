.menu-color-input-color {
    width: 18px;
    height: 18px;
    border-radius: 2px;
    padding: 0;
    background: transparent;
    border: 0;
}
.menu-color-input-color::-webkit-color-swatch-wrapper {
    padding: 0;
}
.menu-color-input-color::-webkit-color-swatch {
    padding: 0;
}

.menu-color-input-input {
    -webkit-appearance: none;
    border: 0;
    outline: none;
    width: 60px;
}

.menu-color-input-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px;
    border: 1px solid #cecece;
    border-radius: 4px;
    width: fit-content;
}

.menu-color-input-text {
    font-family: "Exo 2";
    color: #7B7B7B;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
}