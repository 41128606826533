.justify-text-tile {
    width: 32px;
    height: 30px;
    border: 1px solid #cecece;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    cursor: pointer;
}

.justify-text-tile-selected {
    width: 32px;
    height: 30px;
    border: 1px solid black;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    cursor: pointer;
}
