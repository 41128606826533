.loading-overlay {
    left: 0;
    top: 0;
    position: fixed;
    width: 100%;
    height: 100%;
    background: lightgray;
    z-index: 100;
}

.spinner{
    position: absolute;
    top: calc(50% - 25px);
    left: calc(50% - 25px);
    height : 50px;
    width : 50px;
    border-radius : 50%;
    border : 10px solid #836FFF;
    border-top-color : #211951;
    animation : spin 1s linear infinite;
}

@keyframes spin{
    0%{
        transform : rotate(0deg);
    }
    100%{
        transform : rotate(360deg);
    }
}