.navbar {
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;
    width: 100%;
    background-color: #FFF;
}

.navbar-container {
    padding-left: 160px;
    padding-right: 160px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 120px;
}
@media only screen and (max-width: 1500px) {
    .navbar-container {
        padding-left: 32px;
        padding-right: 32px;
        height: 90px;
    }
}

.navbar-version {
    color: #000;
    font-size: 24px;
    line-height: normal;
}

.navbar-version-date {
    font-size: 24px;
    font-weight: 700;
    line-height: normal;
    background: linear-gradient(276deg, #211951 2.04%, #836FFF 97.47%);
    background-clip: border-box;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.navbar-logout-button-div {
    width: 200px;
}