.menu-select {
    border: 1px solid #cecece;
    padding: 4px;
    border-radius: 4px;
    background-image: url("../../images/arrowDown.svg");
    -webkit-appearance: none;
    -moz-appearance: none;
    background-repeat: no-repeat;
    background-position-x: 98%;
    background-position-y: 50%;

    font-family: "Exo 2";
    color: #7B7B7B;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
}